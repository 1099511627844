export function formatCurrency(value: number = 0, decimals: number = 0): string {
  if (value === null || value === undefined) return '';
  return new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(value);
}

export function roundNumber(value: number = 0, decimals: number = 0): number {
  if (value === null || value === undefined) return 0;
  const factor = Math.pow(10, decimals);
  return Math.round(value * factor) / factor;
}

export function getStatusText(value: number = 0): string {
  switch (value) {
    case 1: return 'Started';        // Apenas se crea la factura
    case 2: return 'In Process';     // La factura ya fue pagada desde snapei, este es el proceso de facturación
    case 3: return 'Rejected';       // Factura rechazada
    case 4: return 'Canceled';       // Factura cancelada
    case 5: return 'Billed';         // Factura pagada
    case 6: return 'Completed';      // Factura creada en el ERP
    case 7: return 'Error';          // Factura con error en el ERP
    default: return 'Indefinido';
  }
}
